import React from 'react'
import {FaInstagram, FaEnvelope} from 'react-icons/fa'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink } from './FooterElements'
import { SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements'
const Footer = () => {
  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Sobre nós</FooterLinkTitle>
                            <FooterLink to="/entrar">Como funciona</FooterLink>
                            <FooterLink to="/entrar">Quem somos</FooterLink>
                            <FooterLink to="/entrar">Missão</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>Jurídico</FooterLinkTitle>
                            <FooterLink to="/entrar">Termos de Serviço</FooterLink>
                            <FooterLink to="/entrar">Política de Privacidade</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Fale conosco</FooterLinkTitle>
                            <FooterLink to="/entrar">Perguntas Frequentes</FooterLink>
                            <FooterLink to="/entrar">Contato e Suporte</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>Mídias sociais</FooterLinkTitle>
                            <FooterLink to="/entrar">Instagram</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/'></SocialLogo>
                    <WebsiteRights>Médico em Casa &copy; {new Date().getFullYear()} Todos os direitos reservados.</WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="https://instagram.com/medicoemcasa" target="_blank" aria-label="instagram">
                            <FaInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="mailto:suporte@medicoemcasa.online" target="_blank" aria-label="e-mail">
                            <FaEnvelope />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer