import React from 'react'
import { ServicesContainer, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH1, ServicesH2, ServicesP } from './ServicesElements'
import icon1 from '../../images/img-04.svg'
import icon2 from '../../images/img-06.svg'
import icon3 from '../../images/img-05.svg'

const Services = () => {
  return (
    <ServicesContainer id='servicos'>
        <ServicesH1>Nós cuidamos de você.</ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={icon1} />
                <ServicesH2>De qualquer lugar</ServicesH2>
                <ServicesP>Basta um clique pra falar com a gente.</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={icon2} />
                <ServicesH2>De qualquer dispotivo</ServicesH2>
                <ServicesP>O que for mais confortável pra você.</ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={icon3} />
                <ServicesH2>Com total segurança</ServicesH2>
                <ServicesP>Todos os seus dados são protegidos por criptografia de ponta.</ServicesP>
            </ServicesCard>
        </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services