import React from 'react'
import styled from 'styled-components'
import { ButtonPraFora } from '../../../homepage/components/ButtonElement'

const MedicoModal = ({id, longdesc, displayname, photo, hideButton}) => {
    const handleLoad = (e) => {
        e.target.scrollIntoView()
    }

  return (

    <MedicoModalWrap>
        <MedicoModalImg onLoad={handleLoad} src={process.env.REACT_APP_LINKSFROM+'/images/' +photo} />
        <MedicoModalText>
            <MedicoModalNome>Dr. {displayname}</MedicoModalNome>
            <MedicoModalBigDesc>{longdesc}</MedicoModalBigDesc>
            {!hideButton && (<MedicoModalButton>            
                <ButtonPraFora 
            primary="true" to={'/marcarconsulta?medico='+id}>
                Marcar Consulta
            </ButtonPraFora>
            </MedicoModalButton>)}
            
        </MedicoModalText>
    </MedicoModalWrap>
  )
}

const MedicoModalNome = styled.h1`
    color: var(--cor-vermelho1)
`

const MedicoModalWrap = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    align-items: center;
    justify-items: center;
    padding-right:20px;

    @media screen and (max-width: 768px){
        flex-direction:column;
    }

`

const MedicoModalImg = styled.img`
    
    height: 300px;
    border: 1px solid var(--cor-vermelho1);
    width: fit-content
`

const MedicoModalText = styled.div`
    margin-left: 20px;
    margin-right:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const MedicoModalButton = styled.div`
    width: fit-content;
    margin-top: 20px;
`

const MedicoModalBigDesc = styled.p`
    padding-top: 10px;
`

export default MedicoModal