import React from 'react'
import Entrar from '../components/Entrar'
import ScrollToTop from '../components/ScrollToTop'

const EntrarPagina = () => {
  return (
      <>
        <ScrollToTop />
        <Entrar />
      </>
    )
}

export default EntrarPagina