import React, {useState, useContext, useEffect} from 'react'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from '../components/InfoSection/Data'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Services from '../components/Services'
import Footer from '../components/Footer'
import { AuthContext } from '../../contexts/Auth/AuthContext'
import { useNavigate } from 'react-router-dom'
import Carregando from '../../Carregando'

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => { setIsOpen(!isOpen) }
    
    const navigate = useNavigate();
    const auth = useContext(AuthContext)
    const [toShow, setToShow] = useState(false);

    useEffect(() =>{
      if(auth.user && auth.user.tipo === 'medico'){
        navigate('/medico')
      }else if( auth.user && auth.user.tipo === 'usuario' ){
        navigate('/usuario')
      } else if (auth.checked && !auth.user){
        setToShow(true);
      }
    }, [auth, navigate]);

    if(!toShow)
    return (<Carregando />)    

    else 
    return (
     <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/>
        <HeroSection />
        <InfoSection {...homeObjOne} />
        <InfoSection {...homeObjTwo} />
        <InfoSection {...homeObjThree} />
        <Services />
        <InfoSection {...homeObjFour} />
        <Footer />
    </> 
  )
}

export default Home