import React,{useState} from 'react'
import Carregando from '../../../Carregando';
import { useApi } from '../../../hooks/useApi';
import Card from './Card';
import { MedicosGrid, MedicosWrap } from './MedicosElements';

const Medicos = () => {
    const api = useApi()
    const [medicos, setMedicos] = useState([]);
    const [gotten, setGotten] = useState(false);

        const doit = async() => {
            if(gotten) return;
         const retorno = await api.getMedicos()
            if(retorno.sucesso){
                setMedicos(retorno.data);
                setGotten(true);
            } else {
                console.log("Não autorizado.");
                window.location.href = '/';
            }
        }
        doit();
        
        if(medicos === []) return (
            <Carregando white={true}/>
        )

    return (
        <MedicosWrap>
            <MedicosGrid>
            {medicos.map((medico, key) => (
                    <Card key={key} {...medico}/>
          ))}

            </MedicosGrid>
        </MedicosWrap>
        
    )
}

export default Medicos