import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
    }
});

export const useApi = () => ({
    formatDateWithSlash: (date) => { return (("0" + date.getDate()).slice(-2) + "/" +
                                    ("0" + (date.getMonth()+1)).slice(-2) + "/" +
                                     date.getFullYear())},
    formatDateStrWithSlash: (dateStr) => {
        const exploded = dateStr.split('-');
        const retorno = exploded[2] + "/" + exploded[1] + "/" + exploded[0];
        return retorno;
    },               

    validateToken: async (token) => {
        const response = await api.post('/auth/validateToken.php', {token})
        return response.data 
    },
    signin: async (cpf, senha) => {
        const response = await api.post('/auth/login.php', {cpf, senha})
        return response.data 
    },
    logout: async () => {
        const response = await api.post('/auth/logout.php');
        return response.data 
    },
    getMedicos: async () =>{
        const response = await api.post('/medico/getAll.php');
        return response.data;
    },
    getMedico: async(id) =>{
        const response = await api.post('/medico/getOne.php', {id});
        return response.data;
    },
    getMedicoDatesAvailable: async(id) =>{
        const response = await api.post('/medico/getDatesAvailable.php', {id})
        return response.data
    },
    getMeusAgendamentos: async() => {
        const response = await api.post('/usuario/getAgendamentos.php');
        return response.data
    },
    getConfirmMedicoDateAvailable: async(medicoid, data, turno) => {
        const response = await api.post('/medico/getConfirmMedicoDateAvailable.php', {medicoid, data, turno})
        return response.data
    },
    getPreQrCode: async(medicoid, data, turno) =>{
        const response = await api.post('/pagamento/gerarqrcode.php', {medicoid, data, turno})
        return response.data
    },
    getQrCodeFromTxid: async(txid) => {
        const response = await api.post('/pagamento/getQrcodeFromTxid.php', {txid})
        return response.data
    },
    getQrCodeByCob: async(cobid) =>{
        const response = await api.post('/pagamento/getQrCodeByCob.php', {cobid})
        return response.data
    },
    getUpdatedPixByCob: async(cobid) => {
        const response = await api.post('/pagamento/getUpdatedPixByCob.php', {cobid})
        return response.data
    },
    getUpdatedPixByCobQrcode: async(qrcode)=>{
        const response = await api.post('/pagamento/getUpdatedPixByCobQrcode.php', {qrcode})
        return response.data
    },
    deleteConsulta: async(consultaid) =>{
        const response = await api.post('/pagamento/deleteConsulta.php', {consultaid})
        return response.data
    },
    getComingForUser: async() => {
        const response = await api.post('/usuario/getComingForUser.php')
        return response.data
    },
    getComingForMedico: async() => {
        const response = await api.post('/medico/getComingForMedico.php')
        return response.data
    },
    registerFormVerificaCPF: async(cpf) => {
        const response = await api.post('/usuario/registerFormVerifica.php', {'variavel': 'cpf', cpf})
        return response.data
    },
    registerFormVerificaEmail: async(email) => {
        const response = await api.post('/usuario/registerFormVerifica.php', {'variavel': 'email', email})
        return response.data
    },
    registerFormSubmit: async(cpf,nome,birth,email,senha) =>{
        const response = await api.post('/usuario/registerFormSubmit.php', {cpf,nome,birth,email,senha})
        return response.data
    },
    sendActivationEmail: async(uid) => {
        const response = await api.post('/usuario/sendActivationEmail.php', {uid})
        return response.data;
    }


})