import React, {useState, useEffect} from 'react'
import Carregando from '../../../Carregando';
import styled from 'styled-components'
import { useApi } from "../../../hooks/useApi";
import { Button } from '../../../homepage/components/ButtonElement';
import PixQrCode from '../Gerencianet/PixQrCode';

const EventoSelecionado = ({medico, evento, eventoSetter}) => {
    const api = useApi()

    const [available, setAvailable] = useState(false)
    const [checked, setChecked] = useState(false)
    const [pix, setPix] = useState(null)
    const [pixQrCode, setPixQrCode] = useState(null)
    const [consultaId, setConsultaId] = useState(null)
    const [loadingPix, setLoadingPix] = useState(false)
    const [message, setMessage] = useState("")

    useEffect(()=>{
      if(checked) return;
      const fetchar = async() =>{
        // parâmetros: ID do médico, dia (Date), Title (horario)
        const retorno = await api.getConfirmMedicoDateAvailable(medico.id, evento.start._d, evento.title);
        
        if(retorno.sucesso){ 
          setChecked(true)
          setAvailable(retorno.data)
        } else {
          alert("Parece que ocorreu um erro. Tente novamente mais tarde.");
        }
      }; fetchar();
    },[api, checked, evento, medico])


    if(!checked) return <Carregando />;
  
    const theDate = evento.start._d;
    const dateString = api.formatDateWithSlash(theDate);

    const marcarConsultaClicked = async () =>{
      ////// VAMOS CONSIDERAR QUE COBRAMOS NO INÍCIO. UMA PRÉ. E GERAR O QR CODE LOGO AQUI
      setLoadingPix(true)
      window.scrollTo(0,99999)
      const retorno = await api.getPreQrCode(medico.id, theDate, evento.title)
      setLoadingPix(false)
      if(retorno.sucesso){ 
        setPix(retorno.pix)
        setConsultaId(retorno.consultaid)
        setPixQrCode(retorno.qrcode)
      }else {
        setPix(null)
        setConsultaId(null)
        setPixQrCode(null)
        setMessage(retorno.message)
      }
    }
    const cancelarClicked = () => {
      ///// LEMBRAR TODO de deletar no banco de dados aqui 
      setPix(null)
      setPixQrCode(null)
      eventoSetter(null)
      api.deleteConsulta(consultaId)
    }

    return (<>
      <EvtSelectedDiv> 
        <EvtSelectedConfirm>
          {(!available) && <>O Dr. {medico.displayname} não possui disponibilidade no dia {dateString}.</>}
          {available &&<>
          O Dr. {medico.displayname} possui disponibilidade no dia {dateString}, no turno da {evento.title}. <br />
          Deseja marcar sua consulta?</>}
          <Button style={{width: 'fit-content', marginTop: '16px', marginLeft: 'auto', marginRight:'auto'}}
            primary="true" to={'pageEnd'} onClick={marcarConsultaClicked}>
                Marcar Consulta
            </Button>
            {loadingPix && <Carregando />}
            {pix && pixQrCode &&
            <EvtSelectedPixBox>
              <br />
              <hr />
              <br />
              <EvtSelectedPixDescription>
                Para evitar atrasos e complicações e facilitar a sua vida, solicitamos uma parte do valor da consulta no momento da marcação. <br /><br />
              </EvtSelectedPixDescription>

              <PixQrCode pix={pix} qrcode={pixQrCode} />
              <button style={{marginTop: '10px'}} onClick={cancelarClicked}>
                Cancelar
              </button>

            </EvtSelectedPixBox>}
            {message && <MessageBoxErro><br />{message}</MessageBoxErro>}
        </EvtSelectedConfirm>
      </EvtSelectedDiv>
              {window.scrollTo(0,99999)}
    </>);
  }
  
  const EvtSelectedDiv = styled.div`
    text-align: center;
    padding: 40px;
  `
  const EvtSelectedPixDescription = styled.p`

  `
  const EvtSelectedConfirm = styled.div`
    border: 1px solid var(--cor-vermelho1);
    padding: 20px;
  `
  const EvtSelectedPixBox = styled.div`
  `
  const MessageBoxErro = styled.p`
    font-weight: bold;
    color: var(--cor-vermelho1);
  `

  export default EventoSelecionado