import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarRoute, SideBtnWrap, SidebarMenu } from './SidebarElements'


const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="primeiro" onClick={toggle}>Conheça</SidebarLink>
                <SidebarLink to="terceiro" onClick={toggle}>Economize</SidebarLink>
                <SidebarLink to="servicos" onClick={toggle}>Saiba mais</SidebarLink>
                <SidebarLink to="quarto" onClick={toggle}>Vamos lá?</SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to="/entrar">
                    Entrar
                </SidebarRoute>
            </SideBtnWrap>
        </SidebarWrapper>
        </SidebarContainer>
  )
}

export default Sidebar