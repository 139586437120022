import "./App.css"
import { Routes, Route, BrowserRouter as Router} from 'react-router-dom'
import Home from "./homepage/pages";
import EntrarPagina from "./homepage/pages/entrar";
import UsuarioOnlinePagina from "./usuario";
import MedicoOnlinePagina from "./medico";
import { AuthProvider } from "./contexts/Auth/AuthProvider";
import { RequireAuth } from "./contexts/Auth/RequireAuth";
import SairPagina from "./homepage/pages/sair";
import Carregando from "./Carregando";
import MarcarConsulta from "./usuario/components/Medico";
import QrCodeFromLocalStorage from "./usuario/components/Gerencianet/QrCodeFromLocalStorage";
import MinhasConsultasPagina from "./usuario/components/MinhasConsultasPagina";
import CadastrarPagina from "./homepage/pages/cadastrar";

function App() {

  

  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact > </Route>
          <Route path="/home" element={<Home />} exact></Route>
          <Route path="/entrar" element={<EntrarPagina />} exact > </Route>
          <Route path="/marcarconsulta" element={<MarcarConsulta />} exact > </Route>

          <Route path="/usuario" element={<RequireAuth tipo={"usuario"}><UsuarioOnlinePagina /></RequireAuth>} exact></Route>
          <Route path="/medico" element={<RequireAuth tipo={"medico"}><MedicoOnlinePagina /></RequireAuth>} exact ></Route>
          <Route path="/logout" element={<SairPagina />} exact > </Route>
          <Route path="/temp" element={<Carregando />} exact > </Route>
          <Route path='/qrcode' element={<QrCodeFromLocalStorage />} exact></Route>
          <Route path='/cadastrar' element={<CadastrarPagina />} exact></Route>
          <Route path='/minhasconsultas' element={<RequireAuth tipo={"usuario"}><MinhasConsultasPagina /></RequireAuth>} exact></Route>
        </Routes>
      </Router>
      <div id='pageEnd'></div>
      </AuthProvider>
      );
}

export default App;
