import React, { useContext } from 'react'
import {FaBars} from 'react-icons/fa'
import {Nav, LogoIcon, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavBtn, NavBtnLink} from './NavbarElements'
import LogoSmallWhite from '../../../homepage/images/logo-small-white.png';
import { IconContext } from 'react-icons/lib'
import { AuthContext } from '../../../contexts/Auth/AuthContext';

const Navbar = ({toggle}) => {

  const authctx = useContext(AuthContext);
  const dispname = authctx.user?.displayname;

  return (
    <IconContext.Provider value={{color: '#000'}}>
    <Nav>
        <NavbarContainer>
        <NavLogo to="/"><LogoIcon src={LogoSmallWhite} alt={"Médico em Casa"} /></NavLogo>

        <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                Bem vindo, {dispname}
              </NavItem>
              <NavItem>&nbsp;|&nbsp;</NavItem>
              <NavItem>
                CRM-{authctx.user?.uf} {authctx.user?.crm}
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to="/logout">Sair</NavBtnLink>
            </NavBtn>
        </NavbarContainer>
    </Nav>
    </IconContext.Provider>
  )
}

export default Navbar