import React from 'react'




const ContentFaturamento = () => {
  return (
    <></>
  )
}

export default ContentFaturamento