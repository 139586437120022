export const homeObjOne = {
    id: 'primeiro',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "A boa medicina",
    headLine: "Médicos qualificados para te atender.",
    description: "Todos os nossos médicos foram aprovados após rigoroso processo de entrevista.",
    buttonLabel: "Entre e conheça-os",
    imgStart: false,
    img: require('../../images/img-01.svg').default,
    alt: 'Médicos',
    btnPraFora: true,
    btnTo: '/cadastrar',
    dark: false,
    primary: true,
    darkText: true
};

export const homeObjTwo = {
    id: 'segundo',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "na sua casa",
    headLine: "Digitalmente, levamos o médico até você.",
    description: "Quer mais?",
    buttonLabel: "Continuar",
    imgStart: true,
    img: require('../../images/img-07.svg').default,
    alt: 'Casa',
    btnPraFora: false,
    btnTo: 'terceiro',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjThree = {
    id: 'terceiro',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "por um preço justo",
    headLine: "Você não precisa gastar uma fortuna pra se cuidar",
    description: "Além das consultas terem valor acessível, você não gasta gasolina pra chegar até nós 😁",
    buttonLabel: "Ver mais",
    imgStart: false,
    img: require('../../images/img-money.svg').default,
    alt: 'Economizando',
    btnPraFora: false,
    btnTo: 'servicos',
    dark: false,
    primary: true,
    darkText: true
};

export const homeObjFour = {
    id: 'quarto',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Vamos lá?",
    headLine: "Marque agora sua primeira consulta digital",
    description: "Estamos só te esperando.",
    buttonLabel: "Comece agora",
    imgStart: true,
    img: require('../../images/img-03.svg').default,
    alt: 'Acenando',
    btnTo: '/cadastrar',
    btnPraFora: true,
    dark: true,
    primary: true,
    darkText: true
};