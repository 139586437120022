import React, {useState, useEffect, useMemo, useCallback} from 'react'
import Navbar from '../Navbar'
import EventoSelecionado from './EventoSelecionado'
import Sidebar from '../Sidebar'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';
import UserView from '../Calendar/UserView'
import { useApi } from '../../../hooks/useApi'
import MedicoModal from '../Medicos/MedicoModal'
import './medico-calendar-css.css'

const colorir = (e) => { return {className: "pt_" + (e.resource || "default")} }

// O MarcaConsulta extrai o ID, e coloca navbar e sidebar para o MarcaConsultaPrincipal.
const MarcarConsulta = () => {

    const [id, setId] = useState(0);
    const getQueryVariable = (v) =>
    {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
        if(pair[0] === v){return pair[1];}
         }
         return(false);
    }

    useEffect(()=>{
        setId(getQueryVariable('medico'));
    }, [])

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => { setIsOpen(!isOpen) }
  

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/>
        <MarcaConsultaPrincipal medid={id}/>
    </>)
}

export default MarcarConsulta


const MarcaConsultaPrincipal = ({medid}) => {
  const [events, setEvents] = useState([]);
  const localizer = momentLocalizer(moment);
  const [medico, setMedico] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const dataHoje = useMemo(() => new Date(), []);
  const api = useApi()

  
  const fetcharDatasMedico = useCallback(async(id, date, start, end) => {
    const datesAvailable = await api.getMedicoDatesAvailable(id)
    if(datesAvailable.sucesso)
      setEvents(translateDatesToCalendar(datesAvailable.data, date, start, end))
    else 
      alert(datesAvailable.message || "Erro de rede.")
  }, [api]);


  const onNavigate = (date, view) => {
    let start, end;
  
    if (view === 'month') {
      start = moment(date).startOf('month').startOf('week')
      end = moment(date).endOf('month').endOf('week')
      fetcharDatasMedico(medid, date, start._d, end._d)
    }
  }

  const onNavigateWithoutArgs = useCallback(
    () => {
      fetcharDatasMedico(medid, dataHoje, moment(dataHoje).startOf('month').startOf('week')._d, moment(dataHoje).endOf('month').endOf('week')._d)
    }, [medid, dataHoje, fetcharDatasMedico])
  



  useEffect(()=>{
    if(medico != null) return
    const firstOpen = async() => {
      const medico = await api.getMedico(medid)
      if(medico.sucesso){
        setMedico(medico.data)
        onNavigateWithoutArgs()
      }
    }
    firstOpen()
  }, [api, medid, medico, dataHoje, onNavigateWithoutArgs])



  const onSelectEvent = (event) => {
    if(document.getElementsByTagName("canvas").length > 0) {alert("Há um pix em aberto. Cancele a marcação atual para selecionar uma nova."); window.scrollTo(0,99999); return;}
    setSelectedEvent(event)
  }

  return <>
    <br />
    {medico && <MedicoModal {... medico} hideButton={true} />}
    <br />
    <hr /> <br />
    <h1 style={{textAlign: 'center'}}>Agenda</h1>
    <br />
     <Calendar
            events={events}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={onSelectEvent}
            culture='pt-br'
            eventPropGetter={colorir}
            selectable={false}
            views={{month: true, minha: UserView}}
            defaultView="month"
            defaultDate={moment().toDate()}
            style={{ minHeight: "800px", padding: '40px' }}
            onNavigate={onNavigate}
            localizer={localizer}
            messages={{
                next: "Próximo",
                previous: "Anterior",
                minha: "Meus agendamentos",
                month: 'Médico',
                today: "Hoje",
                noEventsInRange: "Sem consultas nesse período."
              }} />
    <br />
              {selectedEvent && <EventoSelecionado medico={medico} evento={selectedEvent} eventoSetter={setSelectedEvent}/>}

  </>
}


export const translateDatesToCalendar = (dates, today, start, end) => {
  const fixosStr = dates.fixos
  const daysoff = dates.daysoff
  const extras = dates.extras

  const fixos = fixosStr.split("-")
  let hoje = new Date();
  let day = start;
  let arr = [];
  while(day.getTime() < end.getTime()){
    if(day.getTime() < hoje.getTime()) { day.setDate(day.getDate() + 1); continue; }

    let turnos = toCalendarEvent(day, fixos, daysoff, extras)
      turnos.forEach(turno => {
        arr.push({
          resource: (turno==='Sem atendimento' ? "disabled" : null ),
          title: turno,
          start: moment(day),
          end: moment(day),
          allDay: false
        })
      });
    day.setDate(day.getDate() + 1)
  }
  return arr;
}


const toCalendarEvent = (day, fixos, daysoff, extras) => {
  let turnos = []
  if(daysoff.includes(day)) { turnos.push("Sem atendimento"); return turnos; }
  if(extras.includes(day)) turnos.push("Extra")

  if((fixos[day.getDay()] & 1) === 1) turnos.push("Manhã")
  if((fixos[day.getDay()] & 2) === 2) turnos.push("Tarde")
  if((fixos[day.getDay()] & 4) === 4) turnos.push("Noite")
  return turnos
}
