import React from 'react'
import styled from 'styled-components'

const Carregando = ({white}) => {
  return (
      <LdsBig>    
            <LdsHeard white={white}>
              <Inner white={white}></Inner>
            </LdsHeard>
      </LdsBig>
  )
}
const LdsBig = styled.div`
    top:0%;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    align-items: center;
    justify-content: center;
`

const LdsHeard = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    transform-origin: 40px 40px;

`

const Inner = styled.div`
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: ${({white}) => (white ? '#fff' : '#E43138')};
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);

    &:after{
        content: " ";
        position: absolute;
        display: block;
        width: 32px;
        height: 32px;
        background: ${({white}) => (white ? '#fff' : '#E43138')};
        top: -24px;
        border-radius: 50% 50% 0 0;
    }
    &:before{
        content: " ";
        position: absolute;
        display: block;
        width: 32px;
        height: 32px;
        background: ${({white}) => (white ? '#fff' : '#E43138')};
        left: -24px;
        border-radius: 50% 0 0 50%;
    }
`
export default Carregando