import React, {useState} from 'react'
import styled from 'styled-components'
import Rating from 'react-rating'
import {FaBan} from 'react-icons/fa'
import { useApi } from '../../../hooks/useApi'

const ComponentUmaMarcacaoMed = ({agendamento}) => {
    const api = useApi()
    const [none, setNone] = useState(null)
    
    const color = (str) => {
        switch(str){
            case 'topay': return '#ffcaca';
            case 'paid': return '#caffd1';
            case 'prepaid': return "#34edba";
            case 'done': return '#b3b6bd';
            case 'missed': return '#7c0000b0';
            default: return "#ffffff";
        }
    }
    const statusTxt = (str)=>{
        switch(str){
            case 'topay': return 'Aguardando pagamento';
            case 'prepaid': return 'Aguardando consulta';
            case 'paid': return 'Aguardando consulta';
            case 'done': return 'Consulta realizada';
            case 'missed': return 'Consulta não realizada';
            default: return "Consulta médica";
        }
    }
    const cor = color(agendamento.status);
    const txt = statusTxt(agendamento.status);

    const deleteConsulta = async () =>{
        if(window.confirm("Deseja cancelar este agendamento?")){
            const retorno = await api.deleteConsulta(agendamento.id)
            if(retorno.sucesso){
                setNone("none");
            }
        }
    }
    return ( 
        <ComponentUmaMarcWrap style={{backgroundColor: cor, display: (none || 'flex')}}>
            <UmaMarcData>{api.formatDateStrWithSlash(agendamento.data)}
                <UmaMarcTurno>{agendamento.turno}</UmaMarcTurno>
            </UmaMarcData>
            <UmaMarcMedico>{agendamento.usuarioname}</UmaMarcMedico>
            <UmaMarcStatus>{txt}<br />
            <button title='Cancelar consulta' style={{padding: '3px', marginTop: '5px'}}  onClick={deleteConsulta}>
                   Cancelar <FaBan/>
                    </button>
            </UmaMarcStatus>
           
            {agendamento.status === 'paid' && agendamento.link && <UmaMarcLink>{agendamento.link}</UmaMarcLink>}
            <UmaMarcAvaliacao>
                <Rating initialRating={agendamento.avaliacao/2 || 0} readonly />
            </UmaMarcAvaliacao>
        </ComponentUmaMarcWrap>
    )
}

export default ComponentUmaMarcacaoMed

const ComponentUmaMarcWrap = styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 90%;
`
const UmaMarcData=styled.div``
const UmaMarcTurno=styled.div``
const UmaMarcMedico=styled.div``
const UmaMarcLink=styled.div``
const UmaMarcAvaliacao=styled.div``
const UmaMarcStatus=styled.div`text-align: center`
