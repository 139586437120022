import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useApi } from "../../../hooks/useApi";
import ScrollToTop from '../../../homepage/components/ScrollToTop'
import ComponentUmaMarcacao from "./ComponentUmaMarcacao";

const UserView = () => {
    const api = useApi()
    const [agendadas, setAgendadas] = useState(null)

    useEffect(()=>{
      if(agendadas != null) return;
      const fetchar = async () => {
        const agendamentos = await api.getMeusAgendamentos();
        if(agendamentos.sucesso) {
          setAgendadas(agendamentos.data)
        } else {
          alert("Ocorreu um erro.");
          setAgendadas([]);
        }
      }
      fetchar();

    },[api, agendadas, setAgendadas])

    return (
    <div className="UserView">
      <ScrollToTop />
      {agendadas && agendadas.map((agendamento, i) => (
        <ComponentUmaMarcacao key={i} agendamento={agendamento} />
      )) }

      {agendadas && agendadas.length === 0 && <>
      <div style={{textAlign: 'center'}}>
        <br />
        Você não tem nenhuma consulta marcada 😧<br />
        Comece <a style={{textDecoration: 'none', color: 'red'}} href='/'>agora</a> a cuidar da sua saúde!

      </div>
      </> }
    </div>
      );
  };

UserView.navigate = (date, action) => {
  switch(action){
    case 'PREV': date.setDate(date.getDate() - 1); break;
    case 'NEXT': date.setDate(date.getDate() + 1); break;
    default: break;
  }
  return date;
}


UserView.title = () => "Meus Agendamentos";

export default UserView;