import React, {useEffect, useState, useCallback} from 'react'
import styled from 'styled-components'
import Rating from 'react-rating'
import {FaBan, FaQrcode} from 'react-icons/fa'
import { useApi } from '../../../hooks/useApi';

const ComponentUmaMarcacao = ({agendamento}) => {
    const api = useApi()
    const [medico, setMedico] = useState(null)
    const [none, setNone] = useState(null)

    const fetchar = useCallback(async()=>{
        const result = await api.getMedico(agendamento.medico);
        if(result.sucesso){
            setMedico(result.data)
        }}, [agendamento, api])

    useEffect(()=>{
        if(medico) return;
        fetchar()
    },[fetchar, medico]);
    
    const color = (str) => {
        switch(str){
            case 'topay': return '#ffcaca';
            case 'paid': return '#caffd1';
            case 'prepaid': return "#34edba";
            case 'done': return '#b3b6bd';
            case 'missed': return '#7c0000b0';
            default: return "#ffffff";
        }
    }
    const statusTxt = (str)=>{
        switch(str){
            case 'topay': return 'Aguardando pagamento';
            case 'prepaid': return 'Aguardando consulta';
            case 'paid': return 'Aguardando consulta';
            case 'done': return 'Consulta realizada';
            case 'missed': return 'Consulta não realizada';
            default: return "Consulta médica";
        }
    }
    const cor = color(agendamento.status);
    const txt = statusTxt(agendamento.status);
    const abrirQrCode = async () =>{
        let variavel = 9;
        if(agendamento.status === 'prepaid') { variavel = agendamento.cob_pos }
        if(agendamento.status === 'topay') { variavel = agendamento.cob_pre }
        const retorno = await api.getQrCodeByCob(variavel);
        if(retorno.sucesso){
            localStorage.setItem("pixqrcode", retorno.qrcode)
            window.open('/qrcode', '_blank');    
        } else {
            alert("Erro: " + retorno.message);
        }
    }

    const deleteConsulta = async () =>{
        if(window.confirm("Deseja cancelar este agendamento?")){
            const retorno = await api.deleteConsulta(agendamento.id)
            if(retorno.sucesso){
                setNone("none");
            }
        }
    }
    return ( 
        <ComponentUmaMarcWrap style={{backgroundColor: cor, display: (none || 'flex')}}>
            <UmaMarcData>{api.formatDateStrWithSlash(agendamento.data)}
                <UmaMarcTurno>{agendamento.turno}</UmaMarcTurno>
            </UmaMarcData>
            {medico && <UmaMarcMedico>Dr. {medico.name}</UmaMarcMedico>}
            <UmaMarcStatus>{txt}<br />
            {(agendamento.status==='topay')  && 
            <>
                <button title='Abrir Qrcode' style={{padding: '3px', marginTop: '5px'}} onClick={abrirQrCode}>
                    Pagar <FaQrcode />
                    </button>
                &nbsp;
                <button title='Cancelar consulta' style={{padding: '3px', marginTop: '5px'}}  onClick={deleteConsulta}>
                   Cancelar <FaBan/>
                    </button>
            </>}

            {(agendamento.status==='prepaid')  && 
            <>
                <button title='Cancelar consulta' style={{padding: '3px', marginTop: '5px'}}  onClick={deleteConsulta}>
                   Cancelar <FaBan/>
                    </button>
            </>}
            </UmaMarcStatus>
           
            {agendamento.status === 'paid' && agendamento.link && <UmaMarcLink>{agendamento.link}</UmaMarcLink>}
            <UmaMarcAvaliacao>
                <Rating initialRating={agendamento.avaliacao/2 || 0} readonly />
            </UmaMarcAvaliacao>
        </ComponentUmaMarcWrap>
    )
}

export default ComponentUmaMarcacao

const ComponentUmaMarcWrap = styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 90%;
`
const UmaMarcData=styled.div``
const UmaMarcTurno=styled.div``
const UmaMarcMedico=styled.div``
const UmaMarcLink=styled.div``
const UmaMarcAvaliacao=styled.div``
const UmaMarcStatus=styled.div`text-align: center`
