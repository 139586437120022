import React, {useState, useContext, useRef} from 'react'
import { AuthContext } from '../../../contexts/Auth/AuthContext'
import { Container, Form, BtnsRow, PreviousBtn, NextBtn, Text, FormContent, FormWrap, Icon, FormH1, FormLabel, FormInput, FormButton } from './CadastrarElements'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from 'styled-components'
import { useApi } from '../../../hooks/useApi';

const CadastrarPrinc = () => {
    const auth = useContext(AuthContext)
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    const [cpf, setCpf] = useState('')
    const [nome, setNome] = useState('')
    const [alreadyShowed, setAlreadyShowed] = useState(0)
    const [birth, setBirth] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [erro, setErro] = useState('');
    const [loginButtonActive, setLoginButtonActive] = useState(false);
    const [showing, setShowing] = useState(0);

    const cpfRef = useRef(null);
    const nomeRef = useRef(null);
    const birthRef = useRef(null);
    const emailRef = useRef(null);
    const senhaRef = useRef(null);

    const api = useApi();
    const handleLogin = async () => {

        setErro("Carregando...");
        try
            {
                const retorno = await api.registerFormSubmit(cpf, nome, birth, email, senha)
                if(retorno.sucesso){
                
        
                    if(cpf && senha) {
                        try
                        {
                            const retorno = await auth.signin(cpf, senha)
                            if(retorno.sucesso){
                                window.location.href = "/";
                            } else {
                                 setErro(retorno.message || "Erro ao fazer login. Tente novamente mais tarde.");
                            }
            
                        } catch (e){
                            setErro("Falha na conexão. Por favor verifique se está conectado à rede.")
                        }
                    }
                
                
                
                } else {
                     setErro(retorno.message || "Erro ao fazer o cadastro. Por favor, novamente mais tarde.");
                     return;
                }
            } catch (e){
                setErro("Falha na conexão. Por favor verifique se está conectado à rede.")
            }


      
        }


    // CPF: clicou avançar
    const handleNext1 = async (e) => { 
        e.preventDefault(); 
        setLoginButtonActive(false)

        if(cpfRef.current.value.length < 11){
            //Já fazer uma checagem rápida do número de caracteres.
            setErro("CPF inválido. Por favor, verifique.");
            return;
        } 
         
        let verificaCpf = await api.registerFormVerificaCPF(cpfRef.current.value);
        if(!verificaCpf.sucesso) {
            if(verificaCpf?.message === "Existe.") { 
                setErro ("CPF já cadastrado.")
                setLoginButtonActive(true)
            }
            else setErro(verificaCpf.message || "Verifique a conexão com a internet.")
            return;
        }
        setErro("")
        setShowing(1)
        alreadyShowed < 1 && setAlreadyShowed(1)
    }
    /* Nome: clicou avançar */
    const handleNext2 = (e) => { 
        e.preventDefault(); 
        setShowing(2);  
        alreadyShowed < 2 && setAlreadyShowed(2)
    }
    //Birth: clicou avançar
    const handleNext3 = (e) => { e.preventDefault();  
        if(birthRef.current.value===''){
            setErro("Preencha a sua data de nascimento."); return;
        }
        let dataSelecionada = new Date(birthRef.current.value);
        let hoje = new Date();
        let ano1900 = new Date('1900-03-03');
        if(!(hoje>dataSelecionada) || !(dataSelecionada>ano1900)){
            setErro("Preencha a sua data de nascimento."); return;
        }
        setErro("")
        setShowing(3)
        alreadyShowed < 3 && setAlreadyShowed(3)
    }
    const handleNext4 = async (e) => { 
        e.preventDefault();  
        if(!mailformat.test(emailRef.current.value)){
             setErro("E-mail inválido. Por favor, verifique.")
             return
         }
        let verificaEmail = await api.registerFormVerificaEmail(emailRef.current.value);
        if(!verificaEmail.sucesso) {
            if(verificaEmail?.message === "Existe.") { 
                setErro ("Email já cadastrado.")
            }
            else setErro(verificaEmail.message || "Verifique a conexão com a internet.")
            return;
        }

         setErro("")
         setShowing(4)
         alreadyShowed < 4 && setAlreadyShowed(4)
        }
    const handleNext5 = (e) => { e.preventDefault();  
        const senha = senhaRef.current.value;
        if(senha?.length < 8){
            setErro("A senha deve ter pelo menos 8 caracteres.");
            return;
        }
        if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(senha)){
            setErro("A senha deve conter pelo menos 1 letra e 1 número.");
            return;
        }
        setErro("");
        handleLogin();
    }

      /* CPF == OK!*/
      const handleCpfInput = (e) => { 
        let toadd = e.target.value.replaceAll('.', '').replaceAll('-','');
        if (!/^[0-9]*$/.test(toadd)) return;
        if(toadd.length > 11) return;
        toadd = toadd.split('');
        toadd.length > 3 && toadd.splice(3,0,'.');
        toadd.length > 7 && toadd.splice(7,0,'.');
        toadd.length > 11 && toadd.splice(11,0,'-');
        toadd = toadd.join('');
        setCpf(toadd)  
    }
    /* Nome == OK! está funcionante, mas pode melhorar o REGEX. */
    const handleNomeInput = (e) => { 
        if(e.target.value.length > 60) return;
        // Esse pode melhorar! Detecção dos nomes! TODO
        if (!/[\p{L}\p{M}']*$/.test(e.target.value)) return;
        setNome(e.target.value)
    
    }

    const handleBirthInput = (e) => {  
        setBirth(e.target.value) 
    }

    const handleSenhaInput = (e) => { setSenha(e.target.value) }

    const handleEmailInput = (e) => { setEmail(e.target.value) }

    const handlePrevious = () =>{
        setShowing(showing - 1)
    }
    const handleSimpleNext = () => {
        setShowing(showing + 1)
    }
  return (
        <Container>
            <FormWrap>
                <Icon to="/">Médico em Casa</Icon>
                <FormContent>
                    <Form action="#">
                        <FormH1>Cadastrar-se</FormH1>
                        <BtnsRow>
                        {showing > 0 && <PreviousBtn onClick={handlePrevious}>&lt;</PreviousBtn>}
                        {alreadyShowed > showing && <NextBtn onClick={handleSimpleNext}>&gt;</NextBtn> }
                        </BtnsRow>
                        {showing === 0 &&
                            <CarouselDiv>
                            <FormLabel htmlFor='for'>Digite o seu CPF (somente números)</FormLabel>
                            <FormInput 
 ref={cpfRef} type='tel' value={cpf} onChange={handleCpfInput} required autoFocus={true} />
                            <Text erro={false} style={{marginBottom: '10px'}}>{erro}</Text>
                            {
                            loginButtonActive && <a style={{marginBottom:'8px',color:'red',fontWeight:'bold'}} href='/entrar'>Fazer Login<br /></a>
                            }
                            <FormButton onClick={handleNext1}>Ir</FormButton>
                            </CarouselDiv> }
                            {showing === 1 &&
                            <CarouselDiv>
                            <FormLabel htmlFor='for'>Digite o seu nome completo</FormLabel>
                            <FormInput ref={nomeRef} type='text' autoFocus={true} value={nome} onChange={handleNomeInput} required />
                            <Text erro={false} style={{marginBottom: '10px'}}>{erro}</Text>
                            <FormButton onClick={handleNext2}>Ir</FormButton>
                            </CarouselDiv>}
                            
                            {showing === 2 &&
                            <CarouselDiv>
                            <FormLabel htmlFor='for'>Sua data de nascimento</FormLabel>
                            <FormInput ref={birthRef} autoFocus={true} type='date' value={birth} onChange={handleBirthInput} required  />
                            <Text erro={false} style={{marginBottom: '10px'}}>{erro}</Text>
                            <FormButton onClick={handleNext3}>Ir</FormButton>
                            </CarouselDiv>}

                            {showing === 3 &&
                            <CarouselDiv>
                            <FormLabel htmlFor='for'>Digite o seu endereço de e-mail</FormLabel>
                            <FormInput ref={emailRef} autoFocus={true} type='email' value={email} onChange={handleEmailInput} required  />
                            <Text erro={false} style={{marginBottom: '10px'}}>{erro}</Text>
                            <FormButton onClick={handleNext4}>Ir</FormButton>
                            </CarouselDiv>}

                            {showing === 4 &&
                        <CarouselDiv>
                            <FormLabel htmlFor='for'>Defina uma senha</FormLabel>
                            <FormInput ref={senhaRef} autoFocus={true} type='password' value={senha} onChange={handleSenhaInput} required />
                            <Text erro={false} style={{marginBottom: '10px'}}>{erro}</Text>
                            <FormButton onClick={handleNext5}>Ir</FormButton>
                        </CarouselDiv>}

                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
    )
}

export default CadastrarPrinc

export const CarouselDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`
