import React, {useState, useEffect} from 'react'
import {FaBars} from 'react-icons/fa'
import {Nav, LogoIcon, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink} from './NavbarElements'
import LogoSmall from '../../images/logo-small.png'
import LogoSmallWhite from '../../images/logo-small-white.png';
import { IconContext } from 'react-icons/lib'
import { animateScroll as scroll } from 'react-scroll'

const Navbar = ({toggle}) => {

  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    setScrollNav(window.scrollY >= 80);
  }
  useEffect(()=>{
    window.addEventListener('scroll', changeNav)
    return () => {
      window.removeEventListener('scroll', changeNav)
    }
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop()
  }

  return (
    <IconContext.Provider value={{color: '#fff'}}>
    <Nav scrollNav={scrollNav}>
        <NavbarContainer>
        <NavLogo to="/" onClick={toggleHome}><LogoIcon src={(scrollNav ? LogoSmall : LogoSmallWhite)} alt={"Médico em Casa"} /></NavLogo>
       
        <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem linkNav={scrollNav}>
                <NavLinks to="primeiro" 
                smooth={true} duration={500} spy={true} exact='true' offset={-80} 
                >Conheça</NavLinks>
              </NavItem>
            {/*
            <NavItem linkNav={scrollNav}>
                <NavLinks to="segundo" 
                smooth={true} duration={500} spy={true} exact='true' offset={-80}             
                >Segundo</NavLinks>
  </NavItem> */}
              <NavItem linkNav={scrollNav}>
                <NavLinks to="terceiro" 
                smooth={true} duration={500} spy={true} exact='true' offset={-80}             
                >Economize</NavLinks>
              </NavItem>
              <NavItem linkNav={scrollNav}>
                <NavLinks to="servicos"
                 smooth={true} duration={500} spy={true} exact='true' offset={-80}           
                >Saiba mais</NavLinks>
              </NavItem>
              <NavItem linkNav={scrollNav}>
                <NavLinks to="quarto"
                smooth={true} duration={500} spy={true} exact='true' offset={-80}             
                >Vamos lá?</NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to="/entrar">Entrar</NavBtnLink>
            </NavBtn>
        </NavbarContainer>
    </Nav>
    </IconContext.Provider>
  )
}

export default Navbar