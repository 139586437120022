import styled from 'styled-components'

export const Container = styled.div`
    min-height: 692px;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient( 180deg, rgb(207 70 70) 0%, rgb(255 75 75) 100% );
`



export const FormH1 = styled.h1`
    margin-bottom: 40px;
    color: #010101;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 14px;
    color: #010101;
`
