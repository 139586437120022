import React, {useState, useContext} from 'react'
import { AuthContext } from '../../../contexts/Auth/AuthContext'
import { Container, Form, Text, FormContent, FormWrap, Icon, FormH1, FormLabel, FormInput, FormButton, CadastrarButton } from './EntrarElements'

const Entrar = () => {
    const auth = useContext(AuthContext)
    const [cpf, setCpf] = useState('')
    const [senha, setSenha] = useState('')
    const [erro, setErro] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault()

        if(cpf && senha) {
            try
            {
                const retorno = await auth.signin(cpf, senha)
                if(retorno.sucesso){
                    window.location.href = "/";
                } else {
                     setErro(retorno.message || "Erro ao fazer login. Tente novamente mais tarde.");
                }

            } catch (e){
                setErro("Falha na conexão. Por favor verifique se está conectado à rede.")
            }
        }
    }

    const handleCpfInput = (e) => {
        let toadd = e.target.value.replaceAll('.', '').replaceAll('-','');
        if (!/^[0-9]*$/.test(toadd)) return;
        if(toadd.length > 11) return;
        toadd = toadd.split('');
        toadd.length > 3 && toadd.splice(3,0,'.');
        toadd.length > 7 && toadd.splice(7,0,'.');
        toadd.length > 11 && toadd.splice(11,0,'-');
        toadd = toadd.join('');
        setCpf(toadd)  
    }
    const handleSenhaInput = (e) => {
        setSenha(e.target.value)
    }

  return (
        <Container>
            <FormWrap>
                <Icon to="/">Médico em Casa</Icon>
                <FormContent>
                    <Form action="#">
                        <FormH1>Fazer login no sistema</FormH1>
                        <FormLabel htmlFor='for'>CPF</FormLabel>
                        <FormInput type='text' value={cpf} onChange={handleCpfInput} required autoFocus={true} />
                        <FormLabel htmlFor='for'>Senha</FormLabel>
                        <FormInput type='password' onChange={handleSenhaInput} value={senha} required />
                        <Text erro={false} style={{marginBottom: '10px'}}>{erro}</Text>
                        <FormButton onClick={handleLogin}>Entrar</FormButton>
                        <CadastrarButton to="/cadastrar">Cadastrar</CadastrarButton>
                        <Text style={{cursor: "pointer"}} erro={true}>Esqueci a senha</Text>
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
    )
}

export default Entrar