import React, {useEffect} from 'react'
import { useState } from 'react'
import { useApi } from '../../../hooks/useApi';
import ComponentUmaMarcacaoMed from './ComponentUmaMarcacaoMed';
import styled from 'styled-components';

const ContentProxConsultas = () => {
    const [proxConsultas, setProxConsultas] = useState(null);
    const api = useApi()

    useEffect(()=>{
        const fetchar = async () => {
            const retorno = await api.getComingForMedico()
            if(retorno.sucesso){
                setProxConsultas(retorno.data);
            } else {
                console.log(retorno.message);
            }
        }
        fetchar();

        // eslint-disable-next-line
    }, []);


  return (
    <Wrapper>
    {proxConsultas && proxConsultas.map((agendamento, i) => (
        <ComponentUmaMarcacaoMed key={i} agendamento={agendamento} />
      )) 
    }
</Wrapper>
  )
}

export default ContentProxConsultas

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`