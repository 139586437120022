import { AuthContext } from "./AuthContext"
import { useContext } from "react"
import EntrarPagina from "../../homepage/pages/entrar"

export const RequireAuth = ({children, tipo}) => {
    
    const auth = useContext(AuthContext)
    if(!auth.user || auth.user.tipo !== tipo){
        return <EntrarPagina />
    }

    return children
}

