import React, {useState} from 'react'
import ReactModal from 'react-modal'
import Carregando from '../../../Carregando';
import { useApi } from '../../../hooks/useApi';
import MedicoModal from './MedicoModal.js';
import { MedicosCard, CardInner, NameText, SubText, DescText, MedicosImg, CardText} from './MedicosElements';
import {FaTimesCircle} from 'react-icons/fa'

const Card = ({displayname, photo, crm, uf, id, selfdesc}) => {
    const [showModal, setShowModal] = useState(false);
    const [medico, setMedico] = useState(null);
    const [erro, setErro] = useState('');
    const api = useApi()

    const handleOpenModal = async () =>{
        setShowModal(true);
        const response = await api.getMedico(id)
        if(response.sucesso) {
            setMedico(response.data)
        }
        else {
            setErro(response.message)
        }
    }
    const handleCloseModal = () =>{
        setShowModal(false);
        setMedico(null);
    }

  return (<>
    <ReactModal shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true} shouldFocusAfterRender={true} appElement={document.getElementById('root')}
    style={{overlay: {background: 'var(--cor-vermelho1)', minHeight: '100%', padding: '40px', position: 'relative', inset: 'initial'}, 
    content:{position:'initial', inset:'initial', padding: '20px', border: '1px solid white', textAlign: 'center'}}}
        isOpen={showModal}
        contentLabel={displayname} 
    >           

            {(erro !== '') && <span style={{color: 'red'}}>{erro}</span>}
            {medico !== null ? <><MedicoModal {...medico}/></> : <Carregando />}

    <div style={{position: 'absolute', 
        top: '28px', right: '55px', cursor: 'pointer', zIndex: '2'
    }} onClick={handleCloseModal}>
        <FaTimesCircle style={{color: 'red', width: '25px', height: '25px', background: 'white', borderRadius: '50%'}}/> 
    </div>
    </ReactModal>


    <MedicosCard onClick={handleOpenModal}>
        <CardInner>
            <MedicosImg src={process.env.REACT_APP_LINKSFROM + '/images/'+ photo} alt={displayname}/>
        <CardText>
            <NameText>{displayname}</NameText>
            <SubText>CRM {crm}-{uf}</SubText>
            <DescText>{selfdesc}</DescText>
            </CardText>
        </CardInner>
    </MedicosCard>
    </>
    )
}

export default Card