import React, {useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import Carregando from '../../Carregando';
import { AuthContext } from '../../contexts/Auth/AuthContext';

const SairPagina = () => {

  const navigate = useNavigate();
  const auth = useContext(AuthContext);

    const disney = async () => {
      const response = await auth.signout();
      if(response.sucesso) navigate('/');
      else {
        alert('Ocorreu um erro ao sair.');
        navigate('/');
      }
    }
    disney()
  
  return (
      <>
        <Carregando />
      </>
    )
}

export default SairPagina