import styled from 'styled-components'


export const CardWrap = styled.div`

`
export const CardInner = styled.div`
    display: flex; align-items: center;
    flex-direction: column;
    justify-content: center;

`
export const CardText = styled.div`
    text-align: center;
`
export const NameText = styled.div`
    font-weight: bold;
`
export const SubText = styled.div`
    font-size: 84%;
`
export const DescText = styled.div`
    font-size: 80%;
`

export const MedicosWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    background: transparent;

    
`

export const MedicosGrid = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 40px 50px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr 1fr;
    } 

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr 1fr;
        padding: 0 20px;
    }
    @media screen and (max-width: 480px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

export const MedicosCard = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 700px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  user-select: none;

  &:hover{
      transform: scale(1.05);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
  }
`

export const MedicosImg = styled.img`
    margin-bottom: 10px;
    height: 200px;
    
`
