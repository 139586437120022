import React, {useState} from 'react'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Principal from './components/Principal'

const MedicoOnlinePagina = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => { setIsOpen(!isOpen) }
  

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/>
        <Principal />
    </>
  )
}
export default MedicoOnlinePagina