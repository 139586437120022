import React, {useState} from 'react'
import video00 from '../../videos/video00.mp4'
import video01 from '../../videos/video01.mp4'
import video02 from '../../videos/video02.mp4'
import video03 from '../../videos/video03.mp4'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroBtnWrapper, HeroH1, HeroP, ArrowForward, ArrowRight } from './HeroElements'
import { Button } from '../ButtonElement'

const videos = shuffleArray([video00, video01, video02, video03]);

const HeroSection = () => {

  const [hover, setHover] = useState(false)
  const onHover = () => { setHover(!hover) }

  const [videoAgora, setVideoAgora] = useState(0);
  
  const nextVideo = () => {
      (videoAgora === videos.length-1) ? setVideoAgora(0) : setVideoAgora(videoAgora + 1);
    }

  return (
    <HeroContainer id="home">
        <HeroBg>
            <VideoBg autoPlay muted src={videos[videoAgora]} type='video/mp4' onEnded={nextVideo}/>
        </HeroBg>
        <HeroContent>
            <HeroH1>Atendimento médico?</HeroH1>
            <HeroP>
                Obtenha a ajuda que você precisa, no conforto da sua casa. Ou no trabalho. Ou onde você preferir. É fácil e rápido.
            </HeroP>
            <HeroBtnWrapper>
                <Button to="primeiro" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true" smooth="true" duration={500} spy={true} exact='true' offset={-80} >
                    Conheça {hover ? <ArrowForward /> : <ArrowRight />}
                </Button>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
