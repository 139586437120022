import React, {useState, useEffect, useCallback} from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import UserView from '../Calendar/UserView'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useApi } from '../../../hooks/useApi'
import translateDateTurnoToCalendar from '../Calendar/Extras'


const MinhasConsultasPagina = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => { setIsOpen(!isOpen) }
    
    const [events, setEvents] = useState([]);
    const [fetched, setFetched] = useState(false);
     const localizer = momentLocalizer(moment);
     const api = useApi()

     const fetchar = useCallback(async() => {
      setFetched(true);
      const user_consultas = await api.getComingForUser();
      if(user_consultas.sucesso){
        const evts = [];
        user_consultas.data.forEach(e => {          
          evts.push(translateDateTurnoToCalendar(e.data, e.turno, e.mediconame))
        });
        setEvents(evts)
      }
      else alert('Ocorreu um erro. Por favor, tente novamente mais tarde.')
    }, [api]);
  
     useEffect(()=>{
        if(fetched) return;
        fetchar();
     },[fetchar, fetched])

    return (
      <>
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <Navbar toggle={toggle}/>
          
          <Calendar
            events={events}
            startAccessor="start"
            endAccessor="end"
            culture='pt-br'
            selectable={false}
            views={{ minha: UserView, month: true}}
            defaultView="minha"
            defaultDate={moment().toDate()}
            style={{ minHeight: "800px", padding: '40px' }}
            localizer={localizer}
            messages={{
                next: "Próximo",
                previous: "Anterior",
                minha: "Meus agendamentos",
                month: 'Calendário',
                today: "Hoje",
                noEventsInRange: "Sem consultas nesse período."
              }} />
      </>
    )
}

export default MinhasConsultasPagina