import React, {useRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import {FaCopy} from 'react-icons/fa'
import { useApi } from '../../../hooks/useApi'
import { Checkmark } from './PixConcludedCheckmark'
import { useNavigate } from 'react-router-dom'

const PixQrCode = ({qrcode}) => {

  const theInput = useRef(null)
  const theCanvas = useRef(null)
  const [paid, setPaid] = useState(false)
  const api = useApi()
  const navigate = useNavigate()

  const [idInterval, setIdInterval] = useState(null)

  const copiar = () => {
    let copyText = theInput.current;
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    navigator.clipboard.writeText(copyText.value);
  }

  // Este useEffect pinta o qrcode de vermelho!! Rx o dn
  useEffect(()=>{
    if(paid) return;
   // if(idInterval) return;
    const context = theCanvas.current.getContext("2d")
    const image = new Image()
    image.onload = function (){
      context.drawImage(image, 0, 0, theCanvas.current.width, theCanvas.current.height);
      const imgd = context.getImageData(0,0,theCanvas.current.width,theCanvas.current.height)
      const pixels = imgd.data 
      const uniqueColor = [228, 49, 56];
      for (let i = 0, n = pixels.length; i <n; i += 4) {
        if(pixels[i] === 255) continue;
        pixels[i] = uniqueColor[0];   // Red 
        pixels[i+1] = uniqueColor[1]; // Green 
        pixels[i+2] = uniqueColor[2]; // Blue 
        //pixels[i+3] = opacidade.
      }
      context.putImageData(imgd, 0, 0);

      theCanvas.current.scrollIntoView();
    }

    image.src = qrcode.imagemQrcode

    theInput.current.value = qrcode.qrcode;

    // Parte 2: Setar o Interval para o getUpdatedPixByCob
  
    if(!idInterval){
      const inter = setInterval(async()=>{
        //// GETUPDATEDPIXBYCOB
        const updatedPix = await api.getUpdatedPixByCobQrcode(qrcode);
        if(updatedPix === "ATIVA"){ /* Fazer nada né, tá ok? */} 
        else if (updatedPix === "CONCLUIDA"){
          clearInterval(idInterval)
          setPaid(true)
        } else {  /* ERRO? */ }

      }, 3000);
      setIdInterval(inter)
  }
    
    return () => {
      clearInterval(idInterval);
    }
  
  },[qrcode, api, idInterval, paid, navigate])

  if(paid) return (<Checkmark />)

  return (
    <PixQrcodeWrap>
      <p style={{textAlign: 'center'}}>Aponte a câmera do celular para o QR Code ou copie o código abaixo para realizar o pagamento.
</p>
      <div style={{width:'fit-content', marginLeft:'auto', marginRight:'auto', borderRadius: '10px', marginTop: '10px', padding: '10px', border: '1px solid var(--cor-vermelho1)'}}>
      <canvas style={{width: '228px', height: '228px'}} ref={theCanvas}></canvas>
      <PixTextCopyWrap>
      <input type={'text'} readOnly ref={theInput} />
      <ButtonFaCopy onClick={copiar}>
       <FaCopy />
      </ButtonFaCopy>
      </PixTextCopyWrap>

      </div>
    </PixQrcodeWrap>
  )
}

const ButtonFaCopy = styled.div`
  margin-left: 10px;
  padding: 10px;
  border: 1px solid var(--cor-vermelho1);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover{
    background-color: var(--cor-vermelho1);
  }
`
const PixQrcodeWrap = styled.div`
`

const PixTextCopyWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
`

export default PixQrCode