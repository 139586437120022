import { AuthContext } from "./AuthContext"
import {useState} from "react"
import { useApi } from "../../hooks/useApi";
import { useEffect } from "react";

export const AuthProvider = ({children}) =>{

    const [user, setUser] = useState(null);
    const [checked, setChecked] = useState(false);
    const api = useApi();
    
    useEffect(() => {
        if(user) return;
        const validar = async () => {
            const storageData = localStorage.getItem('authToken')
            if(storageData){
                const data = await api.validateToken(storageData)
                if(data.user){ setUser(data.user) } 
            }
        }
        validar()
        setChecked(true);
    }, [api, user]) 
    

    const signin = async (cpf, senha) => {
        const data = await api.signin(cpf, senha);
        if(data.user && data.token){
            setUser(data.user)
            setToken(data.token)
            return {sucesso: true, user: data.user}
        }
        return {sucesso: false, user: null, message: data.message}
    }

    const signout = async () =>{
        const response = await api.logout()
        setUser(null)
        setToken('')
        return response;
    }

    const setToken = (token) => {
        localStorage.setItem('authToken', token)
    }

    return (
        <AuthContext.Provider value={{checked, user, signin, signout}} >
            {children}
        </AuthContext.Provider>
    )
}