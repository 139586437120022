import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarRoute, SideBtnWrap, SidebarMenu } from './SidebarElements'


const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="/" onClick={toggle}>Início</SidebarLink>
                <SidebarLink to="/minhasconsultas" onClick={toggle}>Agendamentos</SidebarLink>
                <SidebarLink to="/terceiro" onClick={toggle}>Histórico</SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to="/logout">
                    Sair
                </SidebarRoute>
            </SideBtnWrap>
        </SidebarWrapper>
        </SidebarContainer>
  )
}

export default Sidebar