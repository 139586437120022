import React from 'react'
import {FaBars} from 'react-icons/fa'
import {Nav, LogoIcon, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavBtn, NavBtnLink, NavLinkr} from './NavbarElements'
import LogoSmallWhite from '../../../homepage/images/logo-small-white.png';
import { IconContext } from 'react-icons/lib'


const Navbar = ({toggle}) => {
  return (
    <IconContext.Provider value={{color: '#FFF'}}>
    <Nav>
        <NavbarContainer>
        <NavLogo to="/"><LogoIcon src={LogoSmallWhite} alt={"Médico em Casa"} /></NavLogo>

        <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinkr to="/minhasconsultas" 
                smooth='true' duration={500} spy='true' exact='true' offset={-80} 
                >Meus Agendamentos</NavLinkr>
              </NavItem>
       
              <NavItem >
                <NavLinkr to="terceiro" 
                smooth='true' duration={500} spy='true' exact='true' offset={-80}             
                >Histórico de Consultas</NavLinkr>
              </NavItem>
              
            </NavMenu>
            <NavBtn>
              <NavBtnLink to="/logout">Sair</NavBtnLink>
            </NavBtn>
        </NavbarContainer>
    </Nav>
    </IconContext.Provider>
  )
}

export default Navbar