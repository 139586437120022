import React from 'react'
import styled from 'styled-components'
import ContentFaturamento from '../ContentFaturamento'
import ContentProxConsultas from '../ContentProxConsultas'
const Principal = () => {
  return (
    <Container>
        <SubContainer>
            <SubContainerTitle>Próximas Consultas</SubContainerTitle>
            <Hr />
            <Content>
                <ContentProxConsultas />
            </Content>
        </SubContainer>

        <SubContainer>
            <SubContainerTitle>Faturamento</SubContainerTitle>
            <Hr />
            <Content>
                <ContentFaturamento />
            </Content>
        </SubContainer>
    </Container>
  )
}

export default Principal

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    padding: 8px;
`
export const SubContainerTitle = styled.h3`

`
export const Content = styled.div`

`
export const Hr = styled.hr``