import React, { useContext } from 'react'
import Medicos from '../Medicos'
import { Container } from './PrincipalElements'
import { AuthContext } from '../../../contexts/Auth/AuthContext'
import { useApi } from '../../../hooks/useApi'

const Principal = () => {
  const ctx = useContext(AuthContext)
  const api = useApi()

  const enviarEmailTeste = async () =>{
    const retorno = await api.sendActivationEmail(ctx.user.id);
    console.log("Retorno: ");
    console.log(retorno);
  }
  return (
    <>
    <Container >
    <p style={{textAlign: 'left', color: 'white', marginLeft:'25px', marginTop:'25px', marginBottom: '12px', fontSize: '120%'}}>
      
      
      Bem-vindo(a), {ctx.user.name?.split(' ')[0]}!</p>
      <a href='#' onClick={enviarEmailTeste}>Enviar e-mail de ativação</a>
      <Medicos />
    </Container>
    </>
  )
}

export default Principal