import React from 'react'
import PixQrCode from './PixQrCode';

const QrCodeFromLocalStorage = () => {
    const dados = JSON.parse(localStorage.getItem('pixqrcode'));
    console.log(dados);

    return (
     <PixQrCode qrcode={dados} />
      )
}

export default QrCodeFromLocalStorage