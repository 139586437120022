import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

export const Button = styled(LinkS)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#E43138' : '#fff')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 18px' : '12px 30px')};
    color: ${({primary}) => (primary ? '#fff' : '#E43138')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#E43138')};
        color: ${({primary}) => (primary ? '#E43138' : '#fff')};
        border: 1px solid #e43138;
    }
`

export const ButtonPraFora = styled(LinkR)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#E43138' : '#fff')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 18px' : '12px 30px')};
    color: ${({primary}) => (primary ? '#fff' : '#E43138')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#E43138')};
        color: ${({primary}) => (primary ? '#E43138' : '#fff')};
        border: 1px solid #e43138;
    }
`