import moment from "moment";

const translateDateTurnoToCalendar = (date, turno, medico) => {

    const evt = {
        resource: (turno==='Sem atendimento' ? "disabled" : null ),
        title: medico + ' - ' + turno,
        start: moment(date),
        end: moment(date),
        allDay: false
      }
    return evt
}

export default translateDateTurnoToCalendar;