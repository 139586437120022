import React from 'react'
import CadastrarPrinc from '../components/Cadastrar'
import ScrollToTop from '../components/ScrollToTop'

const CadastrarPagina = () => {
  return (
      <>
        <ScrollToTop />
        <CadastrarPrinc />
      </>
    )
}

export default CadastrarPagina