import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
    user-select:none;
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient( 180deg, rgb(207 70 70) 0%, rgb(255 75 75) 100% );
`

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px) {
        height: 80%;
    }
`

export const Icon = styled(Link)`
    margin-left: 32px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;
    @media screen and (max-width: 480px){
        margin-left: 16px;
        margin-top: 8px;
    }
`
export const BtnsRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`
export const PreviousBtn = styled.div`
    display: block;
    height: 30px;
    width:30px;
    background-color: var(--cor-vermelho1);
    text-align: center;
    border-radius: 5px;
    user-select:none;
    cursor:pointer;
    color: white;
    margin-left:0;
    margin-right:auto;
    font-weight:bold;
    line-height:30px;
`
export const NextBtn = styled.div`
    display: block;
    height: 30px;
    font-weight:bold;
    line-height:30px;
    width: 30px;
    background-color: var(--cor-vermelho1);
    text-align: center;
    border-radius: 5px;
    user-select:none;
    cursor:pointer;
    color: white;
    margin-left:auto;
    margin-right:0;
`

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px){
        padding: 10px;
    }
`

export const Form = styled.form`
    background: #FAFAFA;
    max-width: 400px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 80px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba (0,0,0,0.9);
    @media screen and (max-width: 400px){
        padding: 32px 32px;
    }
`

export const FormH1 = styled.h1`
    margin-bottom: 40px;
    color: #010101;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 16px;
    color: #010101;
`

export const FormInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: 2px solid #010101;
    border-radius: 4px;
    outline: none;
    width:100%;
    font-size: 16px;
    &:focus{
        border: 2px solid var(--cor-vermelho1);
    }
`

export const FormButton = styled.button`
    background: var(--cor-vermelho1);
    padding: 16px 0;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    width:100%;
`
export const CadastrarButton = styled(Link)`
    color: var(--cor-vermelho1);
    text-decoration: none;
    text-align: center;
    padding: 16px 0;
    border: 1px solid var(--cor-vermelho1);
    border-radius: 4px;
    background-color: #fff;
    font-size: 20px;
    cursor: pointer;
`
export const Text = styled.span`
    text-align: center;
    margin-top: 24px;
    color: ${({erro}) => (erro ? '#000' : 'red')};
    font-size: 14px;
`